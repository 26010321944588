import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import JSONData from "../../../content/Emails.json"

const parseDate = (strDate) => {
  const dateTokens = strDate.split(".");
  const year = parseInt(dateTokens[0]);
  const month = parseInt(dateTokens[1]);
  const date = parseInt(dateTokens[2]);
  return `${year}년 ${month}월 ${date}일`;
};

const lastDate = parseDate(JSONData[JSONData.length - 1].date);

const EmailListPage = () => (
  <Layout>
    <SEO title={"도냐 키히데와 건초 판사의 비망록"} titleTemplate={`%s`} />
    <h3 style={{ textAlign: `center`, marginBottom: `0.5em` }}>도냐 키히데와 건초 판사의 비망록</h3>
    <h4 style={{ textAlign: `center` }}>지금까지의 모험</h4>
    <br />
    <br />
    <ul style={{ listStyleType: `none`, margin: 0, padding: 0 }}>
      {JSONData.map((data, index) => {
        let date = parseDate(data.date);
	let chapterInfo = data.chapterNumber > 0 ? `제${data.chapterNumber}장 ` : '';
	let title = `${chapterInfo}${data.title}`;
        return <li key={`content_item_${index}`}>{data.link ? <a href={data.link} style={{ color: `red`, textDecorationLine: `none` }}>{title}</a> : title}</li>
      })}
    </ul>
    <p>
      ({lastDate}까지 발송 분)
    </p>
    <br />
    <p>
    * 매주 화, 수, 금 발송하고 있으며 목차에는 최신 발송 분이 반영되지 않을 수 있습니다
    </p>
  </Layout>
);

export default EmailListPage